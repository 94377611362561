










import {Component, Vue} from "vue-property-decorator";
import DownloadList from "./DownloadList.vue";
import axios from "axios";

@Component({
  components: {
    DownloadList,
  },
})
export default class ProtocolDownloads extends Vue {
  public categories: any[] = [];

  public created() {
    axios.get("/downloads/protocols")
      .then((result) => {
        this.categories = result.data;
      });
  }
}
